/* Overall page styles */
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f4f4f9; /* Light background to contrast with teal elements */
  color: #333; /* Standard text color for readability */
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  background-color: #ffffff; /* White background for the main content area */
  padding: 20px;
}

/* Header and links */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  text-decoration: none;
  transition: color 0.3s ease;
}

.App-link:hover {
  color: #21a1f1; /* Slightly darker blue on hover */
}

/* Footer styling */
footer {
  background-color: #00796b;
  color: white;
  text-align: center;
  padding: 16px;
  margin-top: 50px;
  font-size: 14px;
}

footer a {
  color: #ffffff;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

/* Animations */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Flags */
.flag-container {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 20px;
  margin-right: 20px;
}

.flagLang {
  cursor: pointer;
  font-size: 2rem; /* Increase flag size */
  margin-right: 5px;
  position: relative;
}

.flagLang:last-child {
  margin-right: 0;
}

/* Custom button for expand/collapse all */
.expand-collapse-btn {
  appearance: none;
  background-color: #009688;
  border: none;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.expand-collapse-btn:hover {
  background-color: #00796b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  border: 2px solid white;
}

.expand-collapse-btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 150, 136, 0.4);
}

/* Custom button */
.button-3 {
  appearance: none;
  background-color: #009688;
  border: none;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  cursor: pointer;
  align-items: center; /* Vertically center the icon */
  gap: 8px; /* Space between icon and text */
}

.button-3:hover {
  background-color: #00796b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  border: 2px solid white; /* Add a white outline on hover */
}

/* Focus state for accessibility */
.button-3:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 150, 136, 0.4);
}

/* Styling for the chevron icon */
.button-3 i {
  color: white; /* Ensure chevron is white */
  transition: color 0.3s ease;
}

/* Hover effect for the chevron icon */
.button-3:hover i {
  color: white; /* Keep chevron white on hover */
}

/* Select dropdown */
.sort-select {
  margin-left: 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: white;
  color: #333;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.sort-select:hover {
  border-color: #009688;
}

.sort-select:focus {
  outline: none;
  border-color: #00796b;
  box-shadow: 0 0 5px rgba(0, 150, 136, 0.5);
}

/* Main card style */
.w3-card-4 {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
  padding: 20px;
  transition: transform 0.2s ease;
}

/* Section headings */
.w3-large {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 20px;
  color: #009688;
}

.w3-large i {
  margin-right: 10px;
}

/* Progress bars for skills and languages */
.w3-light-grey {
  background-color: #e0e0e0;
  border-radius: 10px;
  margin-bottom: 10px;
}

.w3-teal {
  background-color: #009688;
  height: 24px;
  border-radius: 10px;
  transition: width 0.5s ease;
}

.w3-round-xlarge {
  border-radius: 10px;
}

.w3-small {
  font-size: 12px;
  color: #fff;
}

/* Name and title styling */
.main-name-decorator {
  color: white;
  font-weight: bold;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  padding: 10px 15px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
}

.main-name-decorator h2 {
  font-size: 2.5rem;
  margin: 0;
}

/* List of qualities and interests */
ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

ul li {
  background-color: #f0f0f0;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}

ul li b {
  color: #009688;
}

li:hover {
  background-color: #e0e0e0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .w3-card-4 {
    padding: 15px;
  }

  .w3-large {
    font-size: 1.15rem;
  }

  .main-name-decorator h2 {
    font-size: 2rem;
  }
}

/* Skill Card Styling */
.skill-card {
  background: linear-gradient(135deg, #005349, #004e46); /* Gradient background for a more dynamic look */
  color: white;
  border-radius: 12px; /* More rounded corners for a modern look */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  overflow: hidden; /* Ensure content stays within the card */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  font-weight: bold; /* Makes the text bold */
}

.skill-card:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Increased shadow on hover */
}

/* Skill Category Header */
.skill-category-header {
  display: flex;
  justify-content: space-between; /* Chevron on the right */
  align-items: center;
  padding: 15px 20px;
  background-color: rgba(255, 255, 255, 0.1); /* Transparent background for a subtle effect */
  backdrop-filter: blur(5px); /* Blurring background for a glassy effect */
  border-bottom: 2px solid rgba(255, 255, 255, 0.1); /* Subtle border to separate header */
  color: white;
  transition: background-color 0.3s ease;
}

.skill-category-header p {
  margin: 0;
  font-weight: 700;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  text-transform: uppercase; /* Uppercase for bold headings */
}

.skill-category-header:hover {
  background-color: rgba(255, 255, 255, 0.15); /* Slight background change on hover */
}

/* Chevron icons styling */
.skill-category-header i {
  font-size: 1.5rem;
  color: white; /* Chevron stays white */
  transition: transform 0.3s ease;
}

.skill-category-header:hover i {
  color: #e0e0e0; /* Lighter chevron on hover */
}

.skill-category-header[aria-expanded="true"] i {
  transform: rotate(180deg); /* Rotate chevron when expanded */
}

/* Progress bar container */
.w3-light-grey {
  background-color: rgba(255, 255, 255, 0.15); /* Subtle background with transparency */
  border-radius: 12px;
  margin-bottom: 10px;
  overflow: hidden; /* Ensure rounded corners on progress bar */
  position: relative;
}

/* Progress bar */
.w3-teal {
  background-color: #26a69a; /* Slightly lighter teal */
  height: 24px;
  border-radius: 12px;
  transition: width 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 0.9rem;
  color: white;
  position: relative;
}

.w3-teal::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1); /* Adding a slight overlay for a polished look */
  border-radius: 12px;
}

.w3-teal:hover {
  background-color: #009688; /* Darker teal on hover */
}

/* Additional Transitions */
.skill-card,
.skill-category-header i,
.w3-teal {
  transition: all 0.3s ease;
}
